import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { Container, Divider, Button, Header, Icon, Modal, Image, Card , Form, Sidebar, Menu, Segment , Grid, Label, Dropdown, Comment} from 'semantic-ui-react';
import { Editor } from '@tinymce/tinymce-react';
import { SwatchesPicker } from 'react-color';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css';

import logo from './logo.svg';
import modalSrc from './images/white-image.png';
import './App.css';

const inlineStyles = {
	modal: {
		widht: 'auto',
		height: 'auto',
		marginTop: 'auto',
		top: 'auto',
		marginLeft: 'auto',
		left: 'auto',
		marginRight: 'auto',
		right: 'auto',
	},
	cards: {
		margin: '20px',
		textAlign: 'left',
	},
	cardRemBtn: {
		zIndex: '99',
		float: 'right',
		position: 'relative',
		top: '10px',
		left: '-40px',
	}
};
	
class App extends Component{
	constructor(props){
		super(props);
		this.state = {
			tempId: props.params.userId,
			userId:[],
			userName:'',
			userClass:'',
			userAff:'',
			userPhone:'',
			member:[],
			login:[],
			createGame:[],
			coverImg:[],
			modalOpen: false,
			modalSubOpen: false,
			modalProfileOpen: false,
			modalImgSrc:modalSrc,
			gameList:[],
			gameDelId:[],
			modalConfirmOpen: false,
			cardRowSize:5,
			selectedFile: null,
			selectedFileEdit: null,
			sidebarOpen: false,
			sidebar:[],
			messageBar:[],
			userMsg:[],
			modalMsg:{},
			modalMsgOpen: false,
			clearMsgModal: false,
		};
		let width = window.innerWidth;
		if(width<=500){
			this.state.cardRowSize = 1;
		}else if(width<=800){
			this.state.cardRowSize = 3;
		}
	}
	componentDidMount(){
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=chkUser&tempid='+this.state.tempId;
		fetch(url)
		.then(res=>res.json())
		.then(data=>{
			if(data.email!==undefined){
				this.setState({userId:data.email,userName:data.name});
				this.setState({member:[<Button circular size='small' color='teal' icon='user' onClick={(e)=>{this.setState({sidebarOpen:!this.state.sidebarOpen})}}/>]});
				fetch('https://comedkku.net/projects/e3diot/e3diot.php?act=setTempid&email='+data.email)
				.then(res=>res.json())
				.then(data=>{
					//this.setState({tempId:data.id});
					fetch('https://comedkku.net/projects/e3diot/e3diot.php?act=getUser&email='+this.state.userId)
					.then(res=>res.json())
					.then(data=>{
						if(data.email===undefined||data.fullname===undefined||data.fullname===''){
							fetch("https://comedkku.net/projects/e3diot/e3diot.php?act=setUser&email="+this.state.userId+'&fullname='+this.state.userName)
							.then(res=>res.json())
							.then(data=>{});
						}
						data.fullname = (data.fullname!==undefined&&data.fullname!=='')?data.fullname:this.state.userName;
						data.class = (data.class!==undefined)?data.class:'';
						data.affiliation = (data.affiliation!==undefined)?data.affiliation:'';
						data.phone = (data.phone!==undefined)?data.phone:'';
						this.setState({userName:data.fullname,userClass:data.class,userAff:data.affiliation,userPhone:data.phone});
						this.loadCoverImg();
						this.getUserMsg();
						this.loadGameList();
					});
				});
			}else{
				window.location.href='https://esan3d.com';
			}
		});
		this.interval = setInterval(()=>{this.getUserMsg();this.loadGameList();this.createGameRender();},1000);
	}
	componentWillUnmount() {
		clearInterval(this.interval);
	}
	getUserMsg = () => {
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=getMessage&uid='+this.state.userId;
		fetch(url)
		.then(res=>res.json())
		.then(data=>{
			this.setState({userMsg: data});
			this.getMessageBar();
		});
	}
	readUserMsg = (mid) => {
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=readMessage&src='+this.state.userId+'&mid='+mid;
		fetch(url)
		.then(res=>res.json())
		.then(data=>{
			this.getUserMsg();
		});
	}
	replyUserMsg = () => {
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=setMessage&src='+this.state.userId+'&dest='+this.replyDest.value;
		let formDat = "srcName="+encodeURIComponent(this.state.userName)
					+ "&msg="+encodeURIComponent(this.reply.value);
		let newobj = {id:this.state.modalMsg.id,from:this.state.modalMsg.from,msg:this.state.modalMsg.msg+'||'+this.state.userName+'>>'+this.reply.value,view:this.state.modalMsg.view};
		fetch(url,{
			method:'POST',
			headers:{
				'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'},
			body:formDat})
		.then(res=>res.json())
		.then(data=>{
			this.getUserMsg();
			this.setState({modalMsg:newobj});
		});
	}
	clearUserMsg = () => {
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=clearMessages&src='+this.state.userId;
		fetch(url)
		.then(res=>res.json())
		.then(data=>{
			this.getUserMsg();
		});
	}
	getMessageBar = () => {
		let ct = 0;
		let optsItem = this.state.userMsg.map((ele,i)=>{
				ct += (ele.view===false)?1:0;
				let co = (ele.view===false)?'red':'black';
        		let act = (ele.type==='p2p')?<Dropdown.Item key={i} onClick={(e)=>{this.setState({modalMsg:ele,modalMsgOpen:true});this.readUserMsg(ele.id);}}><Icon color={co} name='mail'/>{'มีข้อความจาก '+ele.from.split("__")[1]}</Dropdown.Item>:
        							<Dropdown.Item key={i} onClick={(e)=>{this.readUserMsg(ele.id);window.location.href='/chapter/'+this.state.tempId+'/'+ele.from.split("__")[0]+'/'+ele.from.split("__")[1];}}><Icon color={co} name='book'/>{ele.msg+' จากห้อง '+ele.from.split("__")[0]}</Dropdown.Item>;
        		return (act);
        	});
		let menco = (ct>0)?'red':'gray';
		let men = (<Dropdown
        item
        simple
        trigger={<span><Label color={menco} circular><Icon name='mail'/>{ct}</Label></span>}
        icon={null}
        direction='right'>
        	<Dropdown.Menu>
        		<Modal open={this.state.clearMsgModal} onClose={this.state.clearMsgModal} trigger={<Dropdown.Item onMouseDown={(e)=>{this.setState({clearMsgModal:true});}} onMouseUp={(e)=>{this.getUserMsg();}}><Icon color='red' name='trash'/>ล้างข้อความทั้งหมด</Dropdown.Item>} basic size='small' style={inlineStyles.modal}>
        			<Header icon="exclamation" content="Warning"/>
        			<Modal.Content>คุณต้องการล้างข้อความทั้งหมด</Modal.Content>
        			<Modal.Actions>
	        			<Button inverted color='red' onClick={(e)=>{this.setState({clearMsgModal:false});this.clearUserMsg();}} onMouseUp={(e)=>{this.getUserMsg();}}>YES</Button>
	        			<Button inverted color='green' onClick={(e)=>{this.setState({clearMsgModal:false});}} onMouseUp={(e)=>{this.getUserMsg();}}>NO</Button>
        			</Modal.Actions>
        		</Modal>
        		<Dropdown.Divider/>
        		{optsItem}
        	</Dropdown.Menu>
        </Dropdown>);
		this.setState({messageBar: men});
	}
	getSideBar = (ele) => {
		let chk = (ele.class==='teacher')?'Admin Profile':'User Profile';
		let men1 = (ele.class==='teacher')?(<Modal trigger={<Menu.Item as='a' onMouseDown={()=>{this.setState({modalProfileOpen:true})}} onMouseUp={()=>{this.createGameRender()}}><Icon name='spy' />{chk}</Menu.Item>} onClose={this.state.modalProfileOpen} open={this.state.modalProfileOpen} style={inlineStyles.modal}>
							<Modal.Header></Modal.Header>
							<Modal.Content>
							{this.getProfileForm(ele,true)}
							</Modal.Content>
							<Modal.Actions>
								<Button color="red" inverted onClick={()=>{this.setState({modalProfileOpen:false});}}>Cancel</Button>
								<Button color="green" inverted onMouseDown={(e)=>{this.userSubmit(e,ele,this.state.userClass);}} onMouseUp={()=>{this.createGameRender()}}>
									<Icon name="checkmark"/>SAVE
								</Button>
							</Modal.Actions>
						</Modal>):
						<Modal trigger={<Menu.Item as='a' onMouseDown={()=>{this.setState({modalProfileOpen:true})}} onMouseUp={()=>{this.createGameRender()}}><Icon name='child' />{chk}</Menu.Item>} onClose={this.state.modalProfileOpen} open={this.state.modalProfileOpen} style={inlineStyles.modal}>
							<Modal.Header></Modal.Header>
							<Modal.Content>
							{this.getProfileForm(ele,false)}
							</Modal.Content>
							<Modal.Actions>
								<Button color="red" inverted onClick={()=>{this.setState({modalProfileOpen:false});}}>Cancel</Button>
								<Button color="green" inverted onMouseDown={(e)=>{this.userSubmit(e,ele,this.state.userClass);}} onMouseUp={()=>{this.createGameRender()}}>
									<Icon name="checkmark"/>SAVE
								</Button>
							</Modal.Actions>
						</Modal>;
		return (<Sidebar
				    as={Menu}
				    animation='overlay'
				    direction='right'
				    icon='labeled'
				    inverted
				    vertical
				    visible={this.state.sidebarOpen}
				  >
				  	<Menu.Item as='a' header style={{lineHeight:'25px'}}>{ele.name}<br/>{ele.email}</Menu.Item>
				    {men1}
				    <Menu.Item as='a' disabled>
				      <Icon name='gamepad' />
				      Games
				    </Menu.Item>
				</Sidebar>)
	}
	getProfileForm = (ele,flg) => {
		let ph1 = (ele.name!==undefined&&ele.name!=='')?ele.name:'ชื่อ-สกุล';
		let ph2 = (ele.phone!==undefined&ele.phone!=='')?ele.phone:'เบอร์โทรศัพท์';
		let ph3 = (ele.affiliation!==undefined&&ele.affiliation!=='')?ele.affiliation:'สังกัด (องค์กร/หน่วยงาน/สถานที่ทำงาน)';
		let eleForm  = (flg)?(<Form>
							<Form.Field>
								<label>Fullname</label>
								<input size="100%" type='text' placeholder={ph1} ref={input=>this.userfname=input}/>
							</Form.Field>
							<Form.Group>
								<Form.Field>
									<label>Phone</label>
									<input type='text' placeholder={ph2} ref={input=>this.userphone=input}/>
								</Form.Field>
							</Form.Group>
							<Form.TextArea label='Affiliation' placeholder={ph3} onChange={(e)=>{this.description.value=e.target.value}}/>
							<Form.Group>
								<Form.Field>
									<input type='hidden' ref={input=>this.useraff=input}/>
								</Form.Field>
							</Form.Group>
						</Form>):(<Form>
							<Form.Field>
								<label>Fullname</label>
								<input size="100%" type='text' placeholder={ph1} ref={input=>this.userfname=input}/>
							</Form.Field>
						</Form>);
		return eleForm;
	}
	userSubmit = (event,ele,cls) => {
		ele.class = cls;
		ele.name = (this.userfname!==undefined&&this.userfname!==null&&this.userfname.value!=='')?this.userfname.value:ele.name;
		ele.affiliation = (this.useraff!==undefined&&this.useraff!==null&&this.useraff.value!=='')?this.useraff.value:ele.affiliation;
		ele.phone = (this.userphone!==undefined&&this.userphone!==null&&this.userphone.value!=='')?this.userphone.value:ele.phone;
		let url = "https://comedkku.net/projects/e3diot/e3diot.php?act=setUser&email="+ele.email;
		let formDat = "class="+encodeURIComponent(ele.class)
						+"&fullname="+encodeURIComponent(ele.name)
						+"&affiliation="+encodeURIComponent(ele.affiliation)
						+"&phone="+encodeURIComponent(ele.phone);
		fetch(url,{
			method:'POST',
			headers:{
				'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'},
			body:formDat})
		.then(res=>res.json())
		.then(data=>{
			window.location.href='/'+this.state.tempId;
		});
	}
	loadCoverImg() {
		fetch("https://comedkku.net/projects/e3diot/covers/list.php?id="+this.state.userId)
		.then(res=>res.json())
		.then(data=>{
			let imgs = data.map((ele,i)=>{
				return(
					<Grid.Column><Button size='medium' onMouseDown={()=>{this.setState({modalImgSrc: 'https://comedkku.net/projects/e3diot/covers/'+ele.img})}} onMouseUp={()=>{this.createGameRender()}}><Image size='big' src={'https://comedkku.net/projects/e3diot/covers/'+ele.img}/></Button></Grid.Column>
				)
			})
			this.setState({coverImg: [<Grid columns={4} padded>{imgs}</Grid>]});
			this.createGameRender();
		})
	}
	uploadCover = (fl) => {
		let ret = '';
		let upComplete = 0;
		if(fl!==null&&fl!==undefined){
			let fileDat = new FormData();
			fileDat.append("file_upload",fl);
			fetch("https://comedkku.net/projects/e3diot/covers/upload.php?id="+this.state.userId,{
				method: 'POST',
				body:fileDat})
			.then(res=>res.json())
			.then(data=>{
				if(data.success==1){
					upComplete = 1;
					ret = data.img;
					this.loadCoverImg();
				}else{
					alert('failedUpload'+data.success);
				}
			});
		}
		return ret;
	}
	getItemForm = (ele,flg) => {
		let imgform = (flg)?<Form.Group><Form.Field><label>Cover Image</label><input type='file' onChange={(e)=>{this.setState({selectedFileEdit: e.target.files[0]});}}/></Form.Field></Form.Group>:'';
		let formEle = (<Form>
							<input type='hidden' value={ele.id} ref={input=>this.id=input}/>
							<input type='hidden' value={ele.classid} ref={input=>this.classid=input}/>
							<Form.Field>
								<label>Device Title</label>
								<input size="100%" type='text' placeholder={ele.title} ref={input=>this.title=input}/>
							</Form.Field>
							<Form.Field>
								<label>Token</label>
								<input size="100%" type='text' placeholder={ele.token} ref={input=>this.token=input}/>
							</Form.Field>
							<Form.Field>
								<label>Device ID</label>
								<input size="100%" type='text' placeholder={ele.devid} ref={input=>this.devid=input}/>
							</Form.Field>
							<Editor apiKey="r0kepg3vc62s823kj4oetohg7soij2cog5ymc8f7nzkk8r3r" initialValue={ele.description} init={{height: 400,menubar: false,plugins: ['print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons'],toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl', quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable', toolbar_mode: 'sliding', contextmenu: "link image imagetools table",content_css: '//www.tiny.cloud/css/codepen.min.css'}} onChange={(e)=>{this.description.value=e.target.getContent()}}/>
							<Form.Group>
								<Form.Field>
									<label>Theme Color</label>
									<SwatchesPicker width="100%" color={ele.theme} onChangeComplete={(c,e)=>{this.theme.value=c.hex}}/>
									<input type='hidden' placeholder={ele.theme} ref={input=>this.theme=input}/>
								</Form.Field>
							</Form.Group>
							<Form.Group>
								<Form.Field>
									<textarea style={{display:'none'}} ref={input=>this.description=input}>{ele.description}</textarea>
								</Form.Field>
							</Form.Group>
							{imgform}
						</Form>);
		return formEle;
	}
	createGameRender() {
		let useract = (this.state.userClass==='teacher')?(<Modal trigger={<Button onMouseDown={()=>this.setState({modalOpen:true})} onMouseUp={()=>{this.createGameRender()}} primary circular icon><Icon circular name="plus"/></Button>} onClose={this.state.modalOpen} open={this.state.modalOpen} style={inlineStyles.modal}>
					<Modal.Header><h3>Select a Cover</h3></Modal.Header>
					<Modal.Content image>
					  <Image wrapped size="medium" src={this.state.modalImgSrc}/>
					  <Modal.Description>
					  {this.state.coverImg}
					  	<Divider horizontal>
							<label>Upload Image</label>
					  	</Divider>
					  	<Form>
						  <Form.Group>
							<Form.Field>
								<input type='file' onChange={(e)=>{this.setState({selectedFile: e.target.files[0]});}}/>
							</Form.Field>
						  </Form.Group>
						  <Button inverted color="yellow" onClick={(e)=>{this.uploadCover(this.state.selectedFile);}} content="UPLOAD"/>
						</Form>
					  </Modal.Description>
					</Modal.Content>
					<Modal.Actions>
						<Button color="red" inverted onMouseDown={()=>{this.setState({modalOpen:false});}} onMouseUp={()=>{this.createGameRender()}}>Cancel</Button>
						<Modal trigger={<Button onMouseDown={()=>{if(this.state.modalImgSrc!=modalSrc){this.setState({modalSubOpen:true})}}} onMouseUp={()=>{this.createGameRender()}} primary icon>Next Step <Icon name="right chevron"/></Button>} onClose={this.state.modalSubOpen} open={this.state.modalSubOpen} style={inlineStyles.modal}>
							<Modal.Header></Modal.Header>
							<Modal.Content>
							{this.getItemForm({id:'',classid:'',title:'ชื่ออุปกรณ์ควบคุม',token:'รหัส token',devid:'รหัสอุปกรณ์',theme:'#000000',description:''},false)}
							</Modal.Content>
							<Modal.Actions>
								<Button color="red" inverted onMouseDown={()=>{this.setState({modalSubOpen:false,modalOpen:false});}} onMouseUp={()=>{this.createGameRender()}}>Cancel</Button>
								<Button color="green" inverted onMouseDown={(e)=>{}} onMouseUp={(e)=>{this.createGameSubmit(e,null);this.loadGameList();this.createGameRender();}}>
									<Icon name="checkmark"/>OK
								</Button>
							</Modal.Actions>
						</Modal>
					</Modal.Actions>
				</Modal>):(<Modal trigger={<Button onMouseDown={()=>this.setState({modalOpen:true})} onMouseUp={()=>{this.createGameRender()}} primary circular icon><Icon circular name="plus"/></Button>} onClose={this.state.modalOpen} open={this.state.modalOpen} style={inlineStyles.modal}>
						<Modal.Header><h3>Add Device</h3></Modal.Header>
						<Modal.Content>
							<Form>
								<Form.Field>
									<label>Device ID</label>
									<input size="100%" type='text' placeholder='กรอกรหัสอุปกรณ์' ref={input=>this.cid=input}/>
								</Form.Field>
							</Form>
						</Modal.Content>
						<Modal.Actions>
							<Button color="red" inverted onMouseDown={()=>{this.setState({modalOpen:false});}} onMouseUp={()=>{this.createGameRender()}}>Cancel</Button>
							<Button color="green" inverted onMouseDown={(e)=>{if(this.cid.value!==''){this.joinGame();}this.setState({modalOpen:false});}} onMouseUp={(e)=>{this.loadGameList();this.createGameRender();}}>
								<Icon name="checkmark"/>OK
							</Button>
						</Modal.Actions>
					</Modal>);
		let wrd1 = (this.state.userClass==='teacher')?'Create Device':'Add Device';
		let wrd2 = (this.state.userClass==='teacher')?'สร้างอุปกรณ์ใหม่':'เพิ่มอุปกรณ์ใหม่';
		this.setState({createGame:[
		<Container style={{margin:'20px'}}>
			<Header as='h4' icon>
				{useract}
				<Header.Subheader>
					{wrd2}
				</Header.Subheader>
			</Header>
			<Divider horizontal>
				<Header as='h4'>
					<div style={{float:'left'}}>Your Devices</div>
				</Header>
			</Divider>
		</Container>
		]});
	}
	loginSubmit = event => {
		event.preventDefault();
		let url = 'https://lms.comedkku.net/afterschool.php?act=chkUser&tempid='+this.tempid.value;
		fetch(url)
		.then(res=>res.json())
		.then(data=>{
			if(data.email!==undefined){
				this.setState({member:[data.email,' ',<Icon circular inverted color='teal' name='user' size='small'/>]});
				this.setState({login:[]});
				this.loadCoverImg();
				this.loadGameList();
			}
		});
	}
	createGameSubmit = async (event,ele) => {
		event.preventDefault();
		let upimg = (ele!==null)?ele.cover:this.state.modalImgSrc;
		this.title.value = (this.title.value===''&&ele!==null)?ele.title:this.title.value;
		this.token.value = (this.token.value===''&&ele!==null)?ele.token:this.token.value;
		this.theme.value = (this.theme.value===''&&ele!==null)?ele.theme:this.theme.value;
		this.devid.value = (this.devid.value===''&&ele!==null)?ele.devid:this.devid.value;
		let uid = this.state.userId;
		let fld = (this.state.userClass==='teacher')?"teacherid":"userid";
		let eid = (this.classid!==null&&this.classid!==undefined&&this.classid.value!=='')?"&classid="+this.classid.value:"";
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=setClassroom&'+fld+'='+uid+eid;
		let formDat = "&cover="+encodeURIComponent(upimg)
						+"&title="+encodeURIComponent(this.title.value)
						+"&token="+encodeURIComponent(this.token.value)
						+"&description="+encodeURIComponent(this.description.value)
						+"&theme="+encodeURIComponent(this.theme.value)
						+"&devid="+encodeURIComponent(this.devid.value);
		if(this.state.selectedFileEdit!==null){
			let fileDat = new FormData();
			fileDat.append("file_upload",this.state.selectedFileEdit);
			fetch("https://comedkku.net/projects/e3diot/covers/upload.php?id="+this.state.userId,{
				method: 'POST',
				body:fileDat})
			.then(res=>res.json())
			.then(data=>{
				if(data.success==1){
					formDat += "&cover="+encodeURIComponent(data.img);
					fetch(url,{
						method:'POST',
						headers:{
							'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'},
						body:formDat})
					.then(res=>res.json())
					.then(data=>{
						this.setState({modalSubOpen:false,modalOpen:false,modalItemOpen:false});
						this.loadCoverImg();
						this.loadGameList();
					});
				}else{
					alert('failedUpload');
				}
			});
		}else{
			formDat += "&cover="+encodeURIComponent(upimg);
			fetch(url,{
				method:'POST',
				headers:{
					'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'},
				body:formDat})
			.then(res=>res.json())
			.then(data=>{
				this.setState({modalSubOpen:false,modalOpen:false,modalItemOpen:false});
				this.loadCoverImg();
				this.loadGameList();
			});
		}
		this.setState({modalOpen:false});
	}
	joinGame(){
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=setClassroom';
		let formDat = "userid="+encodeURIComponent(this.state.userId)
						+"&classid="+encodeURIComponent(this.cid.value);
		fetch(url,{
			method:'POST',
			headers:{
				'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'},
			body:formDat})
		.then(res=>res.json())
		.then(data=>{
			if(data.status==='successful'){

			}else{
				alert('ไม่พบห้องเรียน หรือ คุณมีห้องเรียนนี้อยู่แล้ว');
			}
		});
	}
	deleteGame(){
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=delClassroom';
		let formDat = "classid="+encodeURIComponent(this.state.gameDelId);		
		fetch(url,{
			method:'POST',
			headers:{
				'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'},
			body:formDat})
		.then(res=>res.json())
		.then(data=>{});
	}
	deleteJoin(){
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=delParticipation';
		let formDat = "classid="+encodeURIComponent(this.state.gameDelId)
						+"&uid="+encodeURIComponent(this.state.userId);		
		fetch(url,{
			method:'POST',
			headers:{
				'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'},
			body:formDat})
		.then(res=>res.json())
		.then(data=>{
			this.setState({modalConfirmOpen:false});
			this.loadGameList();
			this.createGameRender();
		});
	}
	loadGameList = () => {
		let tp = (this.state.userClass!=='teacher')?"userid":"teacherid";
		fetch('https://comedkku.net/projects/e3diot/e3diot.php?act=listClassroom&'+tp+'='+this.state.userId)
		.then(res=>res.json())
		.then(data=>{
			let games = data.map((ele,i)=>{
				let stdBtn = (true)?(<Modal basic size="small" style={inlineStyles.modal} open={this.state.modalConfirmOpen} trigger={
							<Button color='red' icon='log out' style={{}} onMouseDown={()=>{this.setState({modalConfirmOpen:true,gameDelId:ele.classid})}} onMouseUp={()=>{this.loadGameList()}}/>}>
								<Header icon="exclamation" content="Warning"/>
								<Modal.Content>คุณต้องการออกจากห้องอุปกรณ์นี้ใช่หรือไม่</Modal.Content>
								<Modal.Actions>
									<Button basic color="red" onMouseDown={(e)=>{this.deleteJoin();}} onMouseUp={()=>{}} inverted>YES</Button>
									<Button basic color="green" onMouseDown={(e)=>{this.setState({modalConfirmOpen:false})}} onMouseUp={()=>{this.loadGameList()}} inverted>NO</Button>
								</Modal.Actions>
							</Modal>):'';
				let btngroup = (this.state.userClass==='teacher'&&ele.userid===this.state.userId)?(<Button.Group>
							<Modal key={i} trigger={<Button onMouseDown={()=>{this.setState({modalItemOpen:i});}} onMouseUp={()=>{this.loadGameList();this.createGameRender();}} primary icon><Icon name="clone outline"/></Button>} onClose={this.state.modalItemOpen===i} open={this.state.modalItemOpen===i} style={inlineStyles.modal}>
								<Modal.Header></Modal.Header>
								<Modal.Content>
								{this.getItemForm(ele,true)}
								</Modal.Content>
								<Modal.Actions>
									<Button color="red" inverted onMouseDown={(e)=>{this.setState({modalItemOpen:false})}} onMouseUp={()=>{this.loadGameList();this.createGameRender();}}>Cancel</Button>
									<Button color="yellow" inverted onMouseDown={(e)=>{this.classid.value='';}} onMouseUp={(e)=>{this.createGameSubmit(e,ele);this.loadGameList();this.createGameRender();}}>Clone</Button>
									<Button color="green" inverted onMouseDown={(e)=>{}} onMouseUp={(e)=>{this.createGameSubmit(e,ele);this.loadGameList();this.createGameRender();}}>Edit</Button>
								</Modal.Actions>
							</Modal>
							<Button.Or text='<>'/>
							<Modal basic size="small" style={inlineStyles.modal} open={this.state.modalConfirmOpen} trigger={
							<Button color='red' icon='delete' style={{}} onMouseDown={()=>{this.setState({modalConfirmOpen:true,gameDelId:ele.classid})}} onMouseUp={()=>{this.loadGameList()}}/>}>
								<Header icon="exclamation" content="Warning"/>
								<Modal.Content>คุณต้องการลบข้อมูลใช่หรือไม่</Modal.Content>
								<Modal.Actions>
									<Button basic color="red" onMouseDown={(e)=>{this.setState({modalConfirmOpen:false});this.deleteGame();}} onMouseUp={()=>{this.loadGameList();this.createGameRender();}} inverted>YES</Button>
									<Button basic color="green" onMouseDown={(e)=>{this.setState({modalConfirmOpen:false})}} onMouseUp={()=>{this.loadGameList()}} inverted>NO</Button>
								</Modal.Actions>
							</Modal>
						</Button.Group>):stdBtn;
				let pendingLb = (<Card href='javascript:void(0)'>
								<Image src={ele.cover} wrapped ui={false} onClick={()=>{window.location.href='/device/'+this.state.tempId+'/'+ele.classid;}}/>
								<Card.Content onClick={()=>{window.location.href='/device/'+this.state.tempId+'/'+ele.classid;}}>
									<Card.Header style={{fontWeight:'bold',color:ele.theme}}>{ele.title}</Card.Header>
									<Card.Meta style={{fontWeight:'bold'}}>Device ID : {ele.classid} &nbsp;</Card.Meta>
								</Card.Content>
								<Card.Content extra onClick={()=>{window.location.href='/device/'+this.state.tempId+'/'+ele.classid;}}>
									
								</Card.Content>
								{btngroup}
							</Card>);
				return(
					pendingLb
				)
			});
			this.setState({gameList:games});
		})
	}
	render(){
		let sender = (this.state.modalMsg.from!==undefined)?this.state.modalMsg.from.split("__")[0]:'';
		let senderName = (this.state.modalMsg.from!==undefined)?this.state.modalMsg.from.split("__")[1]:'';
		let sms = (this.state.modalMsg.msg!==undefined)?this.state.modalMsg.msg.split("||").map((ele,i)=>{
			let out = "";
			if(ele!==''){
				out = (<Comment>
					      <Comment.Avatar><Icon name="user outline"/></Comment.Avatar>
					      <Comment.Author>{ele.split(">>")[0]}</Comment.Author>
					      <Comment.Content>
					        <Comment.Text>{ele.split(">>")[1]}</Comment.Text>
					      </Comment.Content>
					    </Comment>);
			}
			return out;
		}):'';
		return (
		<div className="App">
			<Navbar className="Navbar" variant="dark" expand="lg">
				<Navbar.Brand href="#home" style={{lineHeight:'40px',fontSize:'1.5em'}}>
					<img src={logo} alt="logo" width="40" height="40" className="d-inline-block align-top App-logo"/>{' '}ESAN3D - IoT System
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav"/>
				<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
					<Menu>
						<Modal open={this.state.modalMsgOpen} onClose={this.state.modalMsgOpen} style={inlineStyles.modal}>
							<Modal.Header>Message from {senderName}</Modal.Header>
							<Modal.Content scrolling>
								<Comment.Group>
									{sms}
								</Comment.Group>
							</Modal.Content>
							<Modal.Actions>
							    <Form floated='left'>
							      <Form.TextArea onChange={(e)=>{this.reply.value=e.target.value;}}/>
								  <input type='hidden' ref={input=>this.reply=input}/>
								  <input type='hidden' value={sender} ref={input=>this.replyDest=input}/>
							      <Button content='Reply' labelPosition='left' icon='edit' primary onClick={(e)=>{if(this.reply.value!==''){this.replyUserMsg();}}}/>
								  <Button onClick={(e)=>{this.setState({modalMsgOpen:false});}}>Close</Button>
							    </Form>
							</Modal.Actions>
						</Modal>
						<Menu.Menu position='right'>{this.state.messageBar}</Menu.Menu>
						<Navbar.Text>{this.state.member}</Navbar.Text>
					</Menu>
				</Navbar.Collapse>
			</Navbar>
			<Sidebar.Pushable>
				{this.getSideBar({email:this.state.userId,name:this.state.userName,class:this.state.userClass,affiliation:this.state.userAff,phone:this.state.userPhone})}
				<Sidebar.Pusher>
					{this.state.login}
					{this.state.createGame}
					<Card.Group itemsPerRow={this.state.cardRowSize} style={inlineStyles.cards}>
						{this.state.gameList}
					</Card.Group>
				</Sidebar.Pusher>
			</Sidebar.Pushable>
		</div>
		);
	}
}

export default App;
