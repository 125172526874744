
import React, { Component, createRef } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { Container, Divider, Button, Header, Icon, Modal, Image, Card , Form, Sidebar, Menu, Segment, Breadcrumb, Select, Grid, List, Sticky, Ref, Visibility, Label, Progress, Comment, Dropdown, Table, Item, ButtonGroup, Input, Accordion, AccordionTitle, Loader} from 'semantic-ui-react';
import { Editor } from '@tinymce/tinymce-react';
import { Link } from 'react-router';
import { SwatchesPicker } from 'react-color';

import { smoothScroll } from './smoothScroll.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css';

import logo from './logo.svg';
import modalSrc from './images/white-image.png';
import './App.css';

var Chart = require("react-google-charts").Chart;

const mapData = [
	{
		type: 'content',
	},
	{
		type: 'feedback',
	},
]

const mapData2 = [
	{
		id: 'CSV',
		type: 'Export CSV',
		dev: 'ESAN3D CO., LTD.'
	},
	{
		id: 'Lnreg',
		type: 'Linear Regression',
		dev: 'ESAN3D CO., LTD.'
	},
]

const mapData3 = [
	{

	},
]

const inlineStyles = {
	modal: {
		widht: 'auto',
		height: 'auto',
		marginTop: 'auto',
		top: 'auto',
		marginLeft: 'auto',
		left: 'auto',
		marginRight: 'auto',
		right: 'auto',
	},
	cards: {
		margin: '20px',
		textAlign: 'left',
	},
	cardRemBtn: {
		zIndex: '99',
		float: 'right',
		position: 'relative',
		top: '10px',
		left: '-40px',
	},
};

const hrsOptions = [...Array(25).keys()].map(i=>{return <option value={(i<=23)?i:-1}>{(i<=23)?i:"-"}</option>})
const minOptions = [...Array(60).keys()].map(i=>{return  <option value={i}>{i}</option>})

class Device extends Component{
	constructor(props){
		super(props);
		this.state = {
			tempId: props.params.userId,
			userId:[],
			classId: props.params.classId,
			chapterId: props.params.chapterId,
            devUser: props.params.blynkId,
            devToken: props.params.blynkToken,
			devId: '',
			userName:'',
			userClass:'',
			userAff:'',
			userPhone:'',
			member:[],
			login:[],
			createGame:[],
			coverImg:[],
			modalOpen: false,
			modalSubOpen: false,
			modalProfileOpen: false,
			modalImgSrc:modalSrc,
			gameList:[],
			gameListWrap:[],
			gameListNav:[],
			gameDelId:[],
			modalConfirmOpen: false,
			cardRowSize:4,
			selectedFile: null,
			selectedFileEdit: null,
			sidebarOpen: false,
			sidebar:[],
			mapOpts:[],
			classObj:[],
			chapObj:[],
			gridCols: "equal",
			gridWidth: 3,
			visibleNode: {},
			feedbackListEle:[],
			feedbackUser:{},
			feedbackConfirm:false,
			percent:0,
			chapTitle:'',
			mapSelection: [],
			topicFeedbackConfirm: false,
			messageBar:[],
			userMsg:[],
			modalMsg:{},
			modalMsgOpen: false,
			topicOrd:'',
			clearMsgModal: false,
			mlPlugin:[],
			plugPercent:0,
			modalPlugin: false,
			stickyActive: false,
			gaugeDat: [],
			gaugeVar: [],
			timeChartDat: [],
			selectedBinFile: null,
			firmwareURL: 'No File',
			lnregDataset: '',
			levelDat: [],
			levelVar: [],
			ctrlVar: [],
			ctrlDat: [],
			inputVar: [],
			inputDat: [],
			inputVal: {},
			timerVar: [],
			timerDat: [],
			timerVal: {},
			foldAct: [],
			foldEle: [],
			segColor: ['teal','olive','brown','gray'],
		};
	}
	componentDidMount(){
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=chkUser&tempid='+this.state.tempId;
		fetch(url)
		.then(res=>res.json())
		.then(data=>{
			if(data.email!==undefined){
				this.setState({userId:data.email,userName:data.name});
				this.setState({member:[<Button circular size='small' color='teal' icon='user' onClick={(e)=>{this.setState({sidebarOpen:!this.state.sidebarOpen})}}/>]});
				fetch('https://comedkku.net/projects/e3diot/e3diot.php?act=setTempid&email='+data.email)
				.then(res=>res.json())
				.then(data=>{
					//this.setState({tempId:data.id});
					fetch('https://comedkku.net/projects/e3diot/e3diot.php?act=getUser&email='+this.state.userId)
					.then(res=>res.json())
					.then(data=>{
						if(data.email===undefined||data.fullname===undefined||data.fullname===''){
							fetch("https://comedkku.net/projects/e3diot/e3diot.php?act=setUser&email="+this.state.userId+'&fullname='+this.state.userName)
							.then(res=>res.json())
							.then(data=>{});
						}
						data.fullname = (data.fullname!==undefined&&data.fullname!=='')?data.fullname:this.state.userName;
						data.class = (data.class!==undefined)?data.class:'';
						data.affiliation = (data.affiliation!==undefined)?data.affiliation:'';
						data.phone = (data.phone!==undefined)?data.phone:'';
						this.setState({userName:data.fullname,userClass:data.class,userAff:data.affiliation,userPhone:data.phone});
						this.loadGameList();
						this.loadCoverImg();
						this.getPlugins();
					});
				});
			}else{
				window.location.href = 'https://esan3d.com/';
			}
		});
		this.interval = setInterval(()=>{this.getBlynk();this.getTimeChart();},1000);
	}
	componentWillUnmount() {
		clearInterval(this.interval);
	}
	getTimeChart = () => {
		this.state.gaugeVar.map((ele,i)=>{
			let url = 'https://comedkku.net/projects/e3diot/e3diot_dt.php?act=getTimeChart&cid='+this.state.classId+'&var='+ele;
			fetch(url)
			.then(res=>res.json())
			.then(data=>{
				let dat = [['Time','Value:'+ele],];
				data.map((el,i)=>{
					dat.push([i,parseFloat(el.value)]);
				});
				let chk = false;
				let datArr = this.state.timeChartDat.map((el,i)=>{
					if(el.name===ele){
						el.value = dat;
						chk = true;
					}
					return el;
				});
				if(!chk){
					datArr.push({name:ele,value:dat});
				}
				this.setState({timeChartDat:datArr});
				/*fetch('https://comedkku.net/projects/e3diot/e3diot.php?act=setMessageIot&var='+ele+'&cid='+this.state.classId+'&val='+data[0])
				.then(res=>res.json())
				.then(data=>{

				});*/
			});
		});
	}
	getBlynk = () => {
        //console.log(this.state.gaugeVar);
		this.state.gaugeVar.map((ele,i)=>{
			let url = 'https://iot.esan3d.com/blynk/'+this.state.devToken+'/get/'+ele;
			fetch(url)
			.then(res=>res.json())
			.then(data=>{
				let chk = false;
				let datArr = this.state.gaugeDat.map((el,i)=>{
					if(el.name===ele){
						el.value = parseFloat(data[0]);
						chk = true;
					}
					return el;
				});
				if(!chk){
					datArr.push({name:ele,value:parseFloat(data[0])});
				}
				this.setState({gaugeDat:datArr});
			});
		});
		this.state.levelVar.map((ele,i)=>{
			let url = 'https://iot.esan3d.com/blynk/'+this.state.devToken+'/get/'+ele;
			fetch(url)
			.then(res=>res.json())
			.then(data=>{
				let chk = false;
				let datArr = this.state.levelDat.map((el,i)=>{
					if(el.name===ele){
						el.value = parseFloat(data[0]);
						chk = true;
					}
					return el;
				});
				if(!chk){
					datArr.push({name:ele,value:parseFloat(data[0])});
				}
				this.setState({levelDat:datArr});
			});
		});
		this.state.ctrlVar.map((ele,i)=>{
			let url = 'https://iot.esan3d.com/blynk/'+this.state.devToken+'/get/'+ele;
			fetch(url)
			.then(res=>res.json())
			.then(data=>{
				let chk = false;
				let datArr = this.state.ctrlDat.map((el,i)=>{
					if(el.name===ele){
						el.value = parseFloat(data[0]);
						chk = true;
					}
					return el;
				});
				if(!chk){
					datArr.push({name:ele,value:parseFloat(data[0])});
				}
				this.setState({ctrlDat:datArr});
			});
		});
		this.state.inputVar.map((ele,i)=>{
			let url = 'https://iot.esan3d.com/blynk/'+this.state.devToken+'/get/'+ele;
			fetch(url)
			.then(res=>res.json())
			.then(data=>{
				let chk = false;
				let datArr = this.state.inputDat.map((el,i)=>{
					if(el.name===ele){
						el.value = parseFloat(data[0]);
						chk = true;
					}
					return el;
				});
				if(!chk){
					datArr.push({name:ele,value:parseFloat(data[0])});
				}
				this.setState({inputDat:datArr});
			});
		});
		this.state.timerVar.map((ele,i)=>{
			let url = 'https://iot.esan3d.com/blynk/'+this.state.devToken+'/get/'+ele;
			fetch(url)
			.then(res=>res.json())
			.then(data=>{
				let chk = false;
				let datArr = this.state.timerDat.map((el,i)=>{
					if(el.name===ele){
						el.value = parseFloat(data[0]);
						chk = true;
					}
					return el;
				});
				if(!chk){
					datArr.push({name:ele,value:parseFloat(data[0])});
				}
				this.setState({timerDat:datArr});
			});
		});
	}
	setBlynk = (ele,val,hot) => {
		var c = this.state.ctrlDat;
		c.map((e,i)=>{if(e.name==ele)e['value']=-1;});
		var p = this.state.inputDat;
		p.map((e,i)=>{if(e.name==ele)e['value']=-1;});
		this.setState(prev=>({ctrlDat:c,inputDat:p}));
		let url = 'https://iot.esan3d.com/blynk/'+this.state.devToken+'/update/'+ele+'?value='+val;
		fetch(url)
		.then(res=>{
			if(hot)window.location.reload();
		});
	}
	setTimer = (ele,val,d) => {
		let v = this.state.timerVal[val];
		v = (v==undefined)?d:v;
		let url = 'https://iot.esan3d.com/blynkpy/set?u='+this.state.devUser+'.Blynk.user&p='+ele[0]+' '+ele[1]+' '+ele[2]+' '+ele[3]+' '+v;
		console.log(url);
		fetch(url)
		.then(res=>res.json())
		.then(dat=>{
			window.location.reload();
		});
	}
	setTimerValue = (ele,e,t) => {
		let p = this.state.timerVal;
		let v = (p[ele]!=undefined)?Number(p[ele]):3600*17;
		if(Number(e.target.value)===-1){
			p[ele] = -1;
		}else{
			if(t==="h"){
				let vt = Number(e.target.value)+17;
				vt = (vt>23)?vt-24:vt;
				let h = Math.floor(v/3600);
				let m = Math.floor((v-(3600*h))/60);
				let s = v-(h*3600)-(m*60);
				p[ele] = (vt*3600)+(m*60)+s;
				console.log(h,m,s,e.target.value,vt);
			}else if(t==="m"){
				let vt = Number(e.target.value)*60;
				let h = Math.floor(v/3600);
				let m = Math.floor((v-(3600*h))/60);
				let s = v-(h*3600)-(m*60);
				p[ele] = (h*3600)+vt+s;
				console.log(h,e.target.value,s,vt);
			}else if(t==="s"){
				let vt = Number(e.target.value);
				let h = Math.floor(v/3600);
				let m = Math.floor((v-(3600*h))/60);
				p[ele] = (h*3600)+(m*60)+vt;
				console.log(h,m,e.target.value,vt);
			}
		}
		this.setState({timerVal:p});
	}
	getCsv = (tp) => {
		if(tp==="Lnreg"){
			if(this.lnregDatasetTxt.value!==''){
				window.open('https://comedkku.net/projects/e3diot/dt/'+this.lnregDatasetTxt.value+'?'+Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
			}else{
				alert("Please select dataset!");
			}
		}
	}
	getMLresult = (tp) => {
		if(tp==='Lnreg'){
			if(this.lnregDatasetTxt.value!==''){
				let urltxt = 'https://esan3d.com:10000/file?filename=lnreg/'+this.lnregDatasetTxt.value+'.txt'+'&'+Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
				fetch(urltxt)
				.then(res=>{
					if(res.status===200){
						window.open(urltxt);
					}else{
						alert('Sorry, no results');
					}
				});
			}else{
				alert("Please select dataset!");
			}
		}
	}
	getMLgraph = (tp) => {
		if(tp==='Lnreg'){
			if(this.lnregDatasetTxt.value!==''){
				let urltxt = 'https://esan3d.com:10000/file?filename=lnreg/'+this.lnregDatasetTxt.value+'.png'+'&'+Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
				fetch(urltxt)
				.then(res=>{
					if(res.status===200){
						window.open(urltxt);
					}else{
						alert('Sorry, no results');
					}
				});
			}else{
				alert("Please select dataset!");
			}
		}
	}
	getPlugins = (flag) => {
		let out = [];
		let url = 'https://comedkku.net/projects/e3diot/e3diot_dt.php?act=loadDataset&cid='+this.state.classId+'&chid='+this.state.chapterId+'&uid='+this.state.userId;
		fetch(url)
		.then(res=>res.json())
		.then(data=>{
			let newout = [];
			data.map((ele,i)=>{
				if(ele.plugin==='CSV'){
					let remBtn = ((this.state.userClass==='teacher'&&this.state.userId===this.state.classObj.userid)||true)?<Button floated='right' icon='minus' circular color='gray' onClick={(e)=>{this.delPlugin(ptype);}}/>:'';
					let lnk = (ele.url!=='')?(<a href={ele.url} target='_blank'>Download CSV</a>):'No File';
					let ptype = ele.plugin;
					let addout = (<Table key={ptype}>
							<Table.Header><Table.Row><Table.HeaderCell colSpan='3'>CSV Data{remBtn}</Table.HeaderCell></Table.Row></Table.Header>
							<Table.Body>
								<Table.Row>
									<Table.Cell colSpan='2'>Dataset</Table.Cell>
									<Table.Cell rowSpan='2' textAlign='center' verticalAlign='middle'><Button icon='database' onClick={(e)=>{this.createDataset(ptype);}}/></Table.Cell>
								</Table.Row>
								<Table.Row>
									<Table.Cell colSpan='2'>{lnk}</Table.Cell>
								</Table.Row>
							</Table.Body>
						</Table>);
						newout = newout.map((el,i)=>{if(el!=undefined&&el.key!=ptype)return el;});
						newout.push(addout);
						this.setState({mlPlugin: newout});
				}else if(ele.plugin==='Lnreg'){
					let optlnk = [];
					let lnk = (ele.url!==undefined&&ele.url.length>0)?(<Button onClick={(e)=>{this.getCsv('Lnreg')}} content='Download CSV'/>):'No File';
					if(ele.url!==undefined){
						ele.url.map((e,i)=>{optlnk.push({key:i,text:e,value:e});});
					}
					let ptype = ele.plugin;
					let urltxt = 'https://esan3d.com:10000/file?filename=lnreg/'+ele.dataset+'.txt';
					fetch(urltxt)
					.then(res=>{
						urltxt += '&'+Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
						let restxt = 'No Results';
						if(res.status===200){
							restxt = <a href={urltxt} target='_blank'>Result Report</a>;
						}
						let urlpng = 'https://esan3d.com:10000/file?filename=lnreg/'+ele.dataset+'.png';
						fetch(urlpng)
						.then(res=>{
							urlpng += '&'+Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
							let respng = 'No GraphViz';
							if(res.status===200){
								respng = <a href={urlpng} target='_blank'>GraphViz</a>;
							}
							let addout = (<Table key={ptype}>
									<Table.Header><Table.Row><Table.HeaderCell colSpan='3'>Linear Regression<Button floated='right' icon='minus' circular color='gray' onClick={(e)=>{this.delPlugin(ptype);}}/></Table.HeaderCell></Table.Row></Table.Header>
									<Table.Body>
										<Table.Row>
											<Table.Cell colSpan='2'><Form><input type='hidden' ref={input=>this.lnregDatasetTxt=input}/><Form.Field control={Select} label="Choose Dataset" options={optlnk} defalutValue={this.state.lnregDataset} placeholder='Choose Dataset' onChange={(e,dat)=>{this.lnregDatasetTxt.value=dat.value;}}/></Form></Table.Cell>
											<Table.Cell rowSpan='2' textAlign='center' verticalAlign='middle'><Button icon='database' onClick={(e)=>{this.createDataset(ptype);}}/></Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell colSpan='2'>{lnk}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell colSpan='2'>
											  	<Form>
												  <Form.Group>
													<Form.Field>
														<input type='file' onChange={(e)=>{this.setState({selectedCsvFile: e.target.files[0]});}}/>
													</Form.Field>
												  </Form.Group>
												  <Button inverted color="yellow" onClick={(e)=>{this.uploadDataset(this.state.selectedCsvFile,'lnreg');}} content="UPLOAD"/>
												</Form>
											</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell colSpan='2'>Classification</Table.Cell>
											<Table.Cell rowSpan='2' textAlign='center' verticalAlign='middle'><Button icon='cog' onClick={(e)=>{this.createClassifier(ptype);}}/></Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell colSpan='2'><Button onClick={(e)=>{this.getMLresult('Lnreg')}} content='Results'/><Button onClick={(e)=>{this.getMLgraph('Lnreg')}} content='Graph'/></Table.Cell>
										</Table.Row>
									</Table.Body>
								</Table>);
							newout = newout.map((el,i)=>{if(el!=undefined&&el.key!=ptype)return el;});
							newout.push(addout);
							this.setState({mlPlugin: newout});
						});
					});
				}
			});
		});
		if(flag===true){
			this.setState({mlPlugin: out});
		}
	}
	createDataset = (type) => {
		this.setState({plugPercent:0});
		if(type==='CSV'){
			let url = 'https://comedkku.net/projects/e3diot/e3diot_dt.php?act=createDataset&cid='+this.state.classId+'&chid='+this.state.chapterId;
			fetch(url)
			.then(res=>res.json())
			.then(data=>{
				this.setState({plugPercent:100});
				this.getPlugins();
			});
		}else if(type==='Lnreg'){
			let url = 'https://comedkku.net/projects/e3diot/e3diot_dt.php?act=createLnregDataset&cid='+this.state.classId;
			fetch(url)
			.then(res=>res.json())
			.then(data=>{
				this.setState({plugPercent:100});
				this.getPlugins();
			});
		}
	}
	uploadDataset = (fl,tp) => {
		this.setState({plugPercent:0});
		let ret = '';
		let upComplete = 0;
		if(fl!==null&&fl!==undefined){
			let fileDat = new FormData();
			fileDat.append("file_upload",fl);
			let fname = this.state.classId+"&chid="+tp;
			fname = (tp==='lnreg'&&this.lnregDatasetTxt.value!=='')?this.lnregDatasetTxt.value:fname;
			fetch("https://comedkku.net/projects/e3diot/dt/upload.php?cid="+fname,{
				method: 'POST',
				body:fileDat})
			.then(res=>res.json())
			.then(data=>{
				if(data.success==1){
					this.setState({plugPercent:100});
					upComplete = 1;
					ret = data.csv;
					this.getPlugins();
				}else{
					alert('failedUpload');
				}
			});
		}
		return ret;
	}
	createClassifier = (type) => {
		this.setState({plugPercent:0});
		if(type==='Lnreg'){
			if(this.lnregDatasetTxt.value!==''){
				let url = 'https://esan3d.com:10000/e3diot/lnreg?dataset='+this.lnregDatasetTxt.value;
				fetch(url)
				.then(res=>{
					this.setState({plugPercent:100});
					this.getPlugins();
				});
			}else{
				alert("Please select dataset!");
			}
		}
	}
	uploadFirmware = (fl) => {
		this.setState({plugPercent:0});
		let ret = '';
		let upComplete = 0;
		if(fl!==null&&fl!==undefined){
			let fileDat = new FormData();
			fileDat.append("file_upload",fl);
			fetch("https://comedkku.net/projects/e3diot/devices/upload.php?devid="+this.state.devId,{
				method: 'POST',
				body:fileDat})
			.then(res=>res.json())
			.then(data=>{
				if(data.success==1){
					this.setState({plugPercent:100});
					upComplete = 1;
					ret = data.bin;
				}else{
					alert('failedUpload');
				}
			});
		}
		return ret;
	}
	getUserMsg = () => {
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=getMessage&uid='+this.state.userId;
		fetch(url)
		.then(res=>res.json())
		.then(data=>{
			this.setState({userMsg: data});
			this.getMessageBar();
		});
	}
	readUserMsg = (mid) => {
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=readMessage&src='+this.state.userId+'&mid='+mid;
		fetch(url)
		.then(res=>res.json())
		.then(data=>{
			this.getUserMsg();
		});
	}
	replyUserMsg = () => {
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=setMessage&src='+this.state.userId+'&dest='+this.replyDest.value;
		let formDat = "srcName="+encodeURIComponent(this.state.userName)
					+ "&msg="+encodeURIComponent(this.reply.value);
		let newobj = {id:this.state.modalMsg.id,from:this.state.modalMsg.from,msg:this.state.modalMsg.msg+'||'+this.state.userName+'>>'+this.reply.value,view:this.state.modalMsg.view};
		fetch(url,{
			method:'POST',
			headers:{
				'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'},
			body:formDat})
		.then(res=>res.json())
		.then(data=>{
			this.getUserMsg();
			this.setState({percent:100});
			this.setState({modalMsg:newobj});
		});
	}
	clearUserMsg = () => {
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=clearMessages&src='+this.state.userId;
		fetch(url)
		.then(res=>res.json())
		.then(data=>{
			this.getUserMsg();
		});
	}
	getMessageBar = () => {
		let ct = 0;
		let optsItem = this.state.userMsg.map((ele,i)=>{
				ct += (ele.view===false)?1:0;
				let co = (ele.view===false)?'red':'black';
        		let act = (ele.type==='p2p')?<Dropdown.Item key={i} onClick={(e)=>{this.setState({modalMsg:ele,modalMsgOpen:true});this.readUserMsg(ele.id);}}><Icon color={co} name='mail'/>{'มีข้อความจาก '+ele.from.split("__")[1]}</Dropdown.Item>:
        							<Dropdown.Item key={i} onClick={(e)=>{this.readUserMsg(ele.id);window.location.href='/chapter/'+this.state.tempId+'/'+ele.from.split("__")[0]+'/'+ele.from.split("__")[1];}}><Icon color={co} name='book'/>{ele.msg+' จากห้อง '+ele.from.split("__")[0]}</Dropdown.Item>;
        		return (act);
        	});
		let menco = (ct>0)?'red':'gray';
		let men = (<Dropdown
        item
        simple
        trigger={<span><Label color={menco} circular><Icon name='mail'/>{ct}</Label></span>}
        icon={null}
        direction='right'>
        	<Dropdown.Menu>
        		<Modal open={this.state.clearMsgModal} onClose={this.state.clearMsgModal} trigger={<Dropdown.Item onMouseDown={(e)=>{this.setState({clearMsgModal:true});}} onMouseUp={(e)=>{this.getUserMsg();}}><Icon color='red' name='trash'/>ล้างข้อความทั้งหมด</Dropdown.Item>} basic size='small' style={inlineStyles.modal}>
        			<Header icon="exclamation" content="Warning"/>
        			<Modal.Content>คุณต้องการล้างข้อความทั้งหมด</Modal.Content>
        			<Modal.Actions>
	        			<Button inverted color='red' onMouseDown={(e)=>{this.setState({clearMsgModal:false});this.clearUserMsg();}} onMouseUp={(e)=>{this.getUserMsg();}}>YES</Button>
	        			<Button inverted color='green' onMouseDown={(e)=>{this.setState({clearMsgModal:false});}} onMouseUp={(e)=>{this.getUserMsg();}}>NO</Button>
        			</Modal.Actions>
        		</Modal>
        		<Dropdown.Divider/>
        		{optsItem}
        	</Dropdown.Menu>
        </Dropdown>);
		this.setState({messageBar: men});
	}
	loadMapSelection = () => {
		let mapSel = mapData.map((ele,i)=>{return({key:i,text:ele.type,value:ele.type})});
		this.setState({mapSelection:mapSel});
	}
	listMap = () => {
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=listChapter&classid='+this.state.classId;
		fetch(url)
		.then(res=>res.json())
		.then(data=>{
			let out = data.map((ele,i)=>{
				return({key:i,text:'('+ele.id+') '+ele.title,value:ele.id});
			});
			this.setState({mapOpts: out});
		});
	}
	listFeedback = () => {
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=listFeedback&chid='+this.state.chapterId;
		fetch(url)
		.then(res=>res.json())
		.then(data=>{
			let out = [];
			let fb = data.map((ele,i)=>{
				if(ele.type==='KWL'){
					return(<Modal trigger={<List.Item>
						<List.Icon name='github' size='large' verticalAlign='middle'/>
						<List.Content style={{textAlign:'left'}}>
							<List.Description as='a'>{ele.fullname}</List.Description>
							<List.Description as='a' style={{fontSize:'0.7em'}}>Updated {ele.datetime}</List.Description>
						</List.Content>
					</List.Item>} style={inlineStyles.modal}>
						<Modal.Header><h3>KWL+ Feedback of {ele.fullname}</h3></Modal.Header>
						<Modal.Content scrolling>
							<Header as="h5" dividing><h4>What he/she did know</h4></Header>
					    	<div dangerouslySetInnerHTML={{__html:ele.known}}/>
							<Header as="h5" dividing><h4>What he/she want to know</h4></Header>
					    	<div dangerouslySetInnerHTML={{__html:ele.want}}/>
							<Header as="h5" dividing><h4>What he/she have learned</h4></Header>
					    	<div dangerouslySetInnerHTML={{__html:ele.learn}}/>
							<Header as="h5" dividing><h4>Plus</h4></Header>
					    	<div dangerouslySetInnerHTML={{__html:ele.plus}}/>
						</Modal.Content>
						<Modal.Actions>
						    <Form floated='left'>
						      <Form.TextArea onChange={(e)=>{this.reply.value=e.target.value;}}/>
							  <input type='hidden' ref={input=>this.reply=input}/>
							  <input type='hidden' value={ele.uid} ref={input=>this.replyDest=input}/>
							  <Progress percent={this.state.percent} autoSuccess/>
						      <Button content='Send Message' labelPosition='left' icon='edit' primary onClick={(e)=>{this.setState({percent:0});if(this.reply.value!==''){this.replyUserMsg();}}}/>
						    </Form>
						</Modal.Actions>
					</Modal>);
				}else if(ele.type==='feedback'){
					return(<Modal trigger={<List.Item>
						<List.Icon name='github' size='large' verticalAlign='middle'/>
						<List.Content style={{textAlign:'left'}}>
							<List.Description as='a'>{ele.fullname}</List.Description>
							<List.Description as='a' style={{fontSize:'0.7em'}}>Updated {ele.datetime}</List.Description>
						</List.Content>
					</List.Item>} style={inlineStyles.modal}>
						<Modal.Header><h3>Feedback of {ele.fullname}</h3></Modal.Header>
						<Modal.Content scrolling>
							<Header as='h5'><h4>Q : {ele.title}</h4></Header>
					    	<div dangerouslySetInnerHTML={{__html:ele.feedback}}/>
						</Modal.Content>
						<Modal.Actions>
						    <Form floated='left'>
						      <Form.TextArea onChange={(e)=>{this.reply.value=e.target.value;}}/>
							  <input type='hidden' ref={input=>this.reply=input}/>
							  <input type='hidden' value={ele.uid} ref={input=>this.replyDest=input}/>
							  <Progress percent={this.state.percent} autoSuccess/>
						      <Button content='Send Message' labelPosition='left' icon='edit' primary onClick={(e)=>{this.setState({percent:0});if(this.reply.value!==''){this.replyUserMsg();}}}/>
						    </Form>
						</Modal.Actions>
					</Modal>);
				}
			});
			this.setState({feedbackListEle: fb});
		});
	}
	getSideBar = (ele) => {
		let chk = (ele.class==='teacher')?'Admin Profile':'User Profile';
		let men1 = (ele.class==='teacher')?(<Modal trigger={<Menu.Item as='a' onMouseDown={()=>{this.setState({modalProfileOpen:true})}} onMouseUp={()=>{this.createGameRender()}}><Icon name='spy' />{chk}</Menu.Item>} onClose={this.state.modalProfileOpen} open={this.state.modalProfileOpen} style={inlineStyles.modal}>
							<Modal.Header></Modal.Header>
							<Modal.Content>
							{this.getProfileForm(ele,true)}
							</Modal.Content>
							<Modal.Actions>
								<Button color="red" inverted onClick={()=>{this.setState({modalProfileOpen:false});}}>Cancel</Button>
								<Button color="green" inverted onMouseDown={(e)=>{this.userSubmit(e,ele,this.state.userClass);}} onMouseUp={()=>{this.createGameRender()}}>
									<Icon name="checkmark"/>SAVE
								</Button>
							</Modal.Actions>
						</Modal>):
						<Modal trigger={<Menu.Item as='a' onMouseDown={()=>{this.setState({modalProfileOpen:true})}} onMouseUp={()=>{this.createGameRender()}}><Icon name='child' />{chk}</Menu.Item>} onClose={this.state.modalProfileOpen} open={this.state.modalProfileOpen} style={inlineStyles.modal}>
							<Modal.Header></Modal.Header>
							<Modal.Content>
							{this.getProfileForm(ele,false)}
							</Modal.Content>
							<Modal.Actions>
								<Button color="red" inverted onClick={()=>{this.setState({modalProfileOpen:false});}}>Cancel</Button>
								<Button color="green" inverted onMouseDown={(e)=>{this.userSubmit(e,ele,this.state.userClass);}} onMouseUp={()=>{this.createGameRender()}}>
									<Icon name="checkmark"/>SAVE
								</Button>
							</Modal.Actions>
						</Modal>;
		return (<Sidebar
				    as={Menu}
				    animation='overlay'
				    direction='right'
				    icon='labeled'
				    inverted
				    vertical
				    visible={this.state.sidebarOpen}
				  >
				  	<Menu.Item as='a' header style={{lineHeight:'25px'}}>{ele.name}<br/>{ele.email}</Menu.Item>
				    {men1}
				    <Menu.Item as='a' disabled>
				      <Icon name='gamepad' />
				      Games
				    </Menu.Item>
				</Sidebar>)
	}
	getProfileForm = (ele,flg) => {
		let ph1 = (ele.name!==undefined&&ele.name!=='')?ele.name:'ชื่อ-สกุล';
		let ph2 = (ele.phone!==undefined&ele.phone!=='')?ele.phone:'เบอร์โทรศัพท์';
		let ph3 = (ele.affiliation!==undefined&&ele.affiliation!=='')?ele.affiliation:'สังกัด (องค์กร/หน่วยงาน/สถานที่ทำงาน)';
		let eleForm  = (flg)?(<Form>
							<Form.Field>
								<label>Fullname</label>
								<input size="100%" type='text' placeholder={ph1} ref={input=>this.userfname=input}/>
							</Form.Field>
							<Form.Group>
								<Form.Field>
									<label>Phone</label>
									<input type='text' placeholder={ph2} ref={input=>this.userphone=input}/>
								</Form.Field>
							</Form.Group>
							<Form.TextArea label='Affiliation' placeholder={ph3} onChange={(e)=>{this.description.value=e.target.value}}/>
							<Form.Group>
								<Form.Field>
									<input type='hidden' ref={input=>this.useraff=input}/>
								</Form.Field>
							</Form.Group>
						</Form>):(<Form>
							<Form.Field>
								<label>Fullname</label>
								<input size="100%" type='text' placeholder={ph1} ref={input=>this.userfname=input}/>
							</Form.Field>
						</Form>);
		return eleForm;
	}
	userSubmit = (event,ele,cls) => {
		ele.class = cls;
		ele.name = (this.userfname!==undefined&&this.userfname!==null&&this.userfname.value!=='')?this.userfname.value:ele.name;
		ele.affiliation = (this.useraff!==undefined&&this.useraff!==null&&this.useraff.value!=='')?this.useraff.value:ele.affiliation;
		ele.phone = (this.userphone!==undefined&&this.userphone!==null&&this.userphone.value!=='')?this.userphone.value:ele.phone;
		let url = "https://comedkku.net/projects/e3diot/e3diot.php?act=setUser&email="+ele.email;
		let formDat = "class="+encodeURIComponent(ele.class)
						+"&fullname="+encodeURIComponent(ele.name)
						+"&affiliation="+encodeURIComponent(ele.affiliation)
						+"&phone="+encodeURIComponent(ele.phone);
		fetch(url,{
			method:'POST',
			headers:{
				'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'},
			body:formDat})
		.then(res=>res.json())
		.then(data=>{
			window.location.href = '/chapter/'+this.state.tempId+'/'+this.state.classId+'/'+this.state.chapterId;
		});
	}
	loadCoverImg() {
		fetch("https://comedkku.net/projects/e3diot/e3diot.php?act=getClassroom&classid="+this.state.classId)
		.then(res=>res.json())
		.then(data=>{
			if(this.state.userClass==='teacher'&&this.state.userId===this.state.classObj.userid){
				this.getPlugins(true);
			}
			this.setState({classObj:data,chapTitle:[<Breadcrumb.Section active>Device Room: {data.title}</Breadcrumb.Section>],devId:data.devid});
			this.loadGameList();
			this.createGameRender();
		});
	}
	uploadCover = (fl) => {
		let ret = '';
		let upComplete = 0;
		if(fl!==null&&fl!==undefined){
			let fileDat = new FormData();
			fileDat.append("file_upload",fl);
			fetch("https://comedkku.net/projects/e3diot/pictures/upload.php?id="+this.state.userId,{
				method: 'POST',
				body:fileDat})
			.then(res=>res.json())
			.then(data=>{
				if(data.success==1){
					upComplete = 1;
					ret = data.img;
					this.loadCoverImg();
				}else{
					alert('failedUpload');
				}
			});
		}
		return ret;
	}
	getItemForm = (ele,flg) => {
		let imgform = (false)?<Form.Group><Form.Field><label>Topic Picture</label><input type='file' onChange={(e)=>{this.setState({selectedFileEdit: e.target.files[0]});}}/></Form.Field></Form.Group>:'';
		let selcls = (false)?<Form.Field control={Select} label='Move to Room' options={this.state.mapOpts} placeholder='คลิกเลือก Chapter' onChange={(e)=>{if(e.target.childNodes[0].innerHTML!==undefined){this.chapterid.value=e.target.childNodes[0].innerHTML.substring(1,e.target.childNodes[0].innerHTML.indexOf(')'))}}}/>:'';
		let formEle = (<Form>
							<input type='hidden' value={ele.id} ref={input=>this.id=input}/>
							<input type='hidden' ref={input=>this.classid=input}/>
							<input type='hidden' ref={input=>this.type=input}/>
							{selcls}
							<Form.Field control={Select} label='Type' options={this.state.mapSelection} placeholder='คลิกเลือกประเภท' onChange={(e)=>{if(e.target.childNodes[0].innerHTML!==undefined){this.type.value=e.target.childNodes[0].innerHTML}}}/>
							<Form.Field>
								<label>Topic Title</label>
								<input size="100%" type='text' placeholder={ele.title} ref={input=>this.title=input}/>
							</Form.Field>
							<Form.Field>
								<label>Variable</label>
								<input size="100%" type='text' placeholder={ele.var} ref={input=>this.var=input}/>
							</Form.Field>
							<Form.Field><label>Content</label></Form.Field>
							<Editor apiKey="r0kepg3vc62s823kj4oetohg7soij2cog5ymc8f7nzkk8r3r" initialValue={ele.content} init={{height: 400,menubar: false,plugins: ['print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons'],toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl', quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable', toolbar_mode: 'sliding', contextmenu: "link image imagetools table",content_css: '//www.tiny.cloud/css/codepen.min.css'}} onChange={(e)=>{this.content.value=e.target.getContent()}}/>
							<Form.Group>
								<Form.Field>
									<textarea style={{display:'none'}} ref={input=>this.content=input}>{ele.content}</textarea>
								</Form.Field>
							</Form.Group>
							<Form.Field>
								<label>Theme Color</label>
								<SwatchesPicker width="100%" color={ele.theme} onChangeComplete={(c,e)=>{this.theme.value=c.hex}}/>
								<input size="100%" type='hidden' placeholder={ele.theme} ref={input=>this.theme=input}/>
							</Form.Field>
							{imgform}
						</Form>);
		return formEle;
	}
	getFeedbackForm = (ele) => {
		let selcls = <Form.Field control={Select} label='Move to Chapter' options={this.state.mapOpts} placeholder='คลิกเลือก Chapter' onChange={(e)=>{if(e.target.childNodes[0].innerHTML!==undefined){this.chid.value=e.target.childNodes[0].innerHTML.substring(1,e.target.childNodes[0].innerHTML.indexOf(')'))}}}/>;
		let formEle = (<Form>
						<input type='hidden' value={this.state.userId} ref={input=>this.uid=input}/>
						<input type='hidden' value={this.state.chapterId} ref={input=>this.chid=input}/>
						<Form.Field><label>K (What do I know)</label></Form.Field>
						<Editor apiKey="r0kepg3vc62s823kj4oetohg7soij2cog5ymc8f7nzkk8r3r" initialValue={ele.known} init={{height: 200,menubar: false,plugins: [] ,toolbar: '',content_css: '//www.tiny.cloud/css/codepen.min.css'}} onChange={(e)=>{this.known.value=e.target.getContent()}}/>
						<br/><br/><Form.Field><label>W (What do I want to know)</label></Form.Field>
						<Editor apiKey="r0kepg3vc62s823kj4oetohg7soij2cog5ymc8f7nzkk8r3r" initialValue={ele.want} init={{height: 200,menubar: false,plugins: [] ,toolbar: '',content_css: '//www.tiny.cloud/css/codepen.min.css'}} onChange={(e)=>{this.want.value=e.target.getContent()}}/>
						<br/><br/><Form.Field><label>L (What did I learned)</label></Form.Field>
						<Editor apiKey="r0kepg3vc62s823kj4oetohg7soij2cog5ymc8f7nzkk8r3r" initialValue={ele.learn} init={{height: 200,menubar: false,plugins: [] ,toolbar: '',content_css: '//www.tiny.cloud/css/codepen.min.css'}} onChange={(e)=>{this.learn.value=e.target.getContent()}}/>
						<Form.Group>
							<Form.Field>
								<input type='hidden' ref={input=>this.known=input}/>
								<input type='hidden' ref={input=>this.want=input}/>
								<input type='hidden' ref={input=>this.learn=input}/>
							</Form.Field>
						</Form.Group>
						<br/><Form.Field><label>Plus (อธิบายเพิ่มเติมถึงความรู้ที่ได้ในบทเรียนด้วยภาพ)</label></Form.Field>
						<Editor apiKey="r0kepg3vc62s823kj4oetohg7soij2cog5ymc8f7nzkk8r3r" initialValue={ele.plus} init={{height: 400,menubar: false,plugins: ['print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons'],toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl', quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable', toolbar_mode: 'sliding', contextmenu: "link image imagetools table",content_css: '//www.tiny.cloud/css/codepen.min.css'}} onChange={(e)=>{this.plus.value=e.target.getContent()}}/>
						<Form.Group>
							<Form.Field>
								<textarea style={{display:'none'}} ref={input=>this.plus=input}></textarea>
							</Form.Field>
						</Form.Group>
						<div>
							<Progress percent={this.state.percent} autoSuccess/>
						</div>
						<Button color="green" onMouseDown={(e)=>{this.setState((prevState)=>({percent:0}));this.loadGameList();}} onMouseUp={(e)=>{this.feedbackSubmit(e);}}>SEND</Button>
						<Modal basic size="small" style={inlineStyles.modal} open={this.state.feedbackConfirm} trigger={
						<Button color='red' style={{}} onMouseDown={()=>{this.setState({feedbackConfirm:true})}} onMouseUp={(e)=>{this.loadGameList();}}>RESET</Button>}>
							<Header icon="exclamation" content="Warning"/>
							<Modal.Content>ถ้าตกลงข้อมูลที่กรอกไว้จะหายทั้งหมด<br/><br/>คุณต้องการล้างข้อมูล feedback ใช่หรือไม่</Modal.Content>
							<Modal.Actions>
								<Button basic color="red" onMouseDown={(e)=>{this.setState({feedbackConfirm:false})}} onMouseUp={()=>{this.deleteFeedback();this.loadGameList();}} inverted>YES</Button>
								<Button basic color="green" onMouseDown={(e)=>{this.setState({feedbackConfirm:false})}} onMouseUp={(e)=>{this.loadGameList();}} inverted>NO</Button>
							</Modal.Actions>
						</Modal>
					</Form>);
		return formEle;
	}
	getTopicFeedback = (ele) => {
			return(<Form style={{marginBottom:'10px'}}>
						<Form.Field><label>Feedback (อธิบาย)</label></Form.Field>
						<Editor apiKey="r0kepg3vc62s823kj4oetohg7soij2cog5ymc8f7nzkk8r3r" initialValue={ele.feedback} init={{height: 300,menubar: false,plugins: ['print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons'],toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl', quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable', toolbar_mode: 'sliding', contextmenu: "link image imagetools table",content_css: '//www.tiny.cloud/css/codepen.min.css'}} onChange={(e)=>{this.feedback.value=e.target.getContent()}}/>
						<Form.Group>
							<Form.Field>
								<textarea style={{display:'none'}} ref={input=>this.feedback=input}></textarea>
							</Form.Field>
						</Form.Group>
						<div>
							<Progress percent={this.state.percent} autoSuccess/>
						</div>
						<Button color="green" onMouseDown={(e)=>{this.setState((prevState)=>({percent:0}));this.loadGameList();}} onMouseUp={(e)=>{this.TopicfeedbackSubmit(e,ele);}}>SEND</Button>
						<Modal basic size="small" style={inlineStyles.modal} open={this.state.topicFeedbackConfirm===ele.id} trigger={
						<Button color='red' style={{}} onMouseDown={()=>{this.setState({topicFeedbackConfirm:ele.id})}} onMouseUp={(e)=>{this.loadGameList();}}>RESET</Button>}>
							<Header icon="exclamation" content="Warning"/>
							<Modal.Content>ถ้าตกลงข้อมูลที่กรอกไว้จะหายทั้งหมด<br/><br/>คุณต้องการล้างข้อมูล feedback ใช่หรือไม่</Modal.Content>
							<Modal.Actions>
								<Button basic color="red" onMouseDown={(e)=>{this.setState({topicFeedbackConfirm:false})}} onMouseUp={()=>{this.deleteTopicFeedback(ele);this.loadGameList();}} inverted>YES</Button>
								<Button basic color="green" onMouseDown={(e)=>{this.setState({topicFeedbackConfirm:false})}} onMouseUp={(e)=>{this.loadGameList();}} inverted>NO</Button>
							</Modal.Actions>
						</Modal>
					</Form>);
	}
	createGameRender() {
		let wrd1 = (this.state.userClass==='teacher'||true)?'New Topic':'...';
		let wrd2 = (this.state.userClass==='teacher'||true)?'สร้างเนื้อหาใหม่':'...';
		this.setState({createGame:wrd1});
	}
	contextRef = createRef();
	loginSubmit = event => {
		event.preventDefault();
		let url = 'https://lms.comedkku.net/afterschool.php?act=chkUser&tempid='+this.tempid.value;
		fetch(url)
		.then(res=>res.json())
		.then(data=>{
			if(data.email!==undefined){
				this.setState({member:[data.email,' ',<Icon circular inverted color='teal' name='user' size='small'/>]});
				this.setState({login:[]});
				this.loadCoverImg();
				this.loadGameList();
			}
		});
	}
	createGameSubmit = (event,ele) => {
		event.preventDefault();
		let upimg = (ele!==null&&ele.picture!==undefined)?ele.picture:this.state.modalImgSrc;
		this.type.value = (this.type.value===''&&ele!==null)?ele.type:this.type.value;
		this.var.value = (this.var.value===''&&ele!==null)?ele.var:this.var.value;
		this.title.value = (this.title.value===''&&ele!==null)?ele.title:this.title.value;
		this.theme.value = (this.theme.value===''&&ele!==null)?ele.theme:this.theme.value;
		this.classid.value = (this.classid.value===''&&ele!==null)?ele.classid:this.classid.value;
		let eid = (this.id!==null&&this.id!==undefined&&this.id.value!=='')?"&id="+this.id.value:"";
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=setTopic'+eid;
		let formDat = "&type="+encodeURIComponent(this.type.value)
						+"&title="+encodeURIComponent(this.title.value)
						+"&content="+encodeURIComponent(this.content.value)
						+"&theme="+encodeURIComponent(this.theme.value)
						+"&var="+encodeURIComponent(this.var.value)
						+"&classid="+encodeURIComponent(this.classid.value);
		if(this.state.selectedFileEdit!==null){
			let fileDat = new FormData();
			fileDat.append("file_upload",this.state.selectedFileEdit);
			fetch("https://comedkku.net/projects/e3diot/pictures/upload.php?id="+this.state.userId,{
				method: 'POST',
				body:fileDat})
			.then(res=>res.json())
			.then(data=>{
				if(data.success==1){
					formDat += "&picture="+encodeURIComponent(data.img);
					fetch(url,{
						method:'POST',
						headers:{
							'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'},
						body:formDat})
					.then(res=>res.json())
					.then(data=>{
						this.setState({modalSubOpen:false,modalOpen:false,modalItemOpen:false});
						this.loadCoverImg();
						this.loadGameList();
						this.setMessage(this.state.chapterId);
					});
				}else{
					alert('failedUpload');
				}
			});
		}else{
			//formDat += "&picture="+encodeURIComponent(upimg);
			fetch(url,{
				method:'POST',
				headers:{
					'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'},
				body:formDat})
			.then(res=>res.json())
			.then(data=>{
				this.setState({modalSubOpen:false,modalOpen:false,modalItemOpen:false});
				this.loadCoverImg();
				this.loadGameList();
				this.setMessage(this.state.chapterId);
			});
		}
		this.setState({modalOpen:false});
	}
	setMessage(chid){
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=setMessage';
		let formDat = "classid="+this.state.classId
					+ "&chid="+chid
					+ "&msg="+encodeURIComponent('มีหัวข้อใหม่ในอุปกรณ์');
		fetch(url,{
			method:'POST',
			headers:{
				'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'},
			body:formDat})
		.then(res=>res.json())
		.then(data=>{

		});
	}
	feedbackSubmit = (event) => {
		event.preventDefault();
		let ele = this.state.feedbackUser;
		ele.known = (this.known.value!==ele.known&&this.known.value!=='')?this.known.value:ele.known;
		ele.want = (this.want.value!==ele.want&&this.want.value!=='')?this.want.value:ele.want;
		ele.learn = (this.learn.value!==ele.learn&&this.learn.value!=='')?this.learn.value:ele.learn;
		ele.plus = (this.plus.value!==ele.plus&&this.plus.value!=='')?this.plus.value:ele.plus;
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=setFeedback';
		let formDat = "&known="+encodeURIComponent(ele.known)
						+"&want="+encodeURIComponent(ele.want)
						+"&learn="+encodeURIComponent(ele.learn)
						+"&plus="+encodeURIComponent(ele.plus)
						+"&chid="+encodeURIComponent(this.state.chapterId)
						+"&uid="+encodeURIComponent(this.state.userId);
		fetch(url,{
			method:'POST',
			headers:{
				'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'},
			body:formDat})
		.then(res=>res.json())
		.then(data=>{
			if(data.status==='successful'){
				this.setState({percent:100});
				this.loadGameList();
			}
		});
	}
	TopicfeedbackSubmit = (event,ele) => {
		event.preventDefault();
		let feedback = (this.feedback.value!==ele.feedback&&this.feedback.value!=='')?this.feedback.value:ele.feedback;
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=setTopicFeedback';
		let formDat = "feedback="+encodeURIComponent(feedback)
						+"&tid="+encodeURIComponent(ele.id)
						+"&uid="+encodeURIComponent(this.state.userId);
		fetch(url,{
			method:'POST',
			headers:{
				'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'},
			body:formDat})
		.then(res=>res.json())
		.then(data=>{
			if(data.status==='successful'){
				this.setState({percent:100});
				this.loadGameList();
			}
		});
	}
	deleteGame(){
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=delTopic';
		let formDat = "id="+encodeURIComponent(this.state.gameDelId);		
		fetch(url,{
			method:'POST',
			headers:{
				'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'},
			body:formDat})
		.then(res=>res.json())
		.then(data=>{
			
		});
	}
	deleteFeedback(){
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=delFeedback';
		let formDat = "chid="+encodeURIComponent(this.state.chapterId)
					+ "&uid="+encodeURIComponent(this.state.userId);		
		fetch(url,{
			method:'POST',
			headers:{
				'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'},
			body:formDat})
		.then(res=>res.json())
		.then(data=>{});
	}
	deleteTopicFeedback(ele){
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=delTopicFeedback';
		let formDat = "tid="+encodeURIComponent(ele.id)
					+ "&uid="+encodeURIComponent(this.state.userId);
		fetch(url,{
			method:'POST',
			headers:{
				'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'},
			body:formDat})
		.then(res=>res.json())
		.then(data=>{});
	}
	setMark(flag,tid){
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=setMark';
		let formDat = "flag="+encodeURIComponent(flag)
					+ "&uid="+encodeURIComponent(this.state.userId)
					+ "&tid="+encodeURIComponent(tid);		
		fetch(url,{
			method:'POST',
			headers:{
				'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'},
			body:formDat})
		.then(res=>res.json())
		.then(data=>{this.loadGameList();});
	}
	loadGameList = () => {
        fetch("https://iot.esan3d.com/blynkpy/get?u="+this.state.devUser+".Blynk.user",{method: "GET"})
        .then(res=>res.json())
        .then(dat=>{
            let gv = [];
			let lv = [];
			let cv = [];
			let tv = [];
			let iv = [];
            dat.profile.dashBoards[0].widgets.map((e,i)=>{
                if(e.type==="GAUGE"){
                    gv.push("V"+e.pin);
                }else if(e.type==="LEVEL_DISPLAY"){
					lv.push("V"+e.pin);
				}else if(e.type==="SEGMENTED_CONTROL" || e.type==="STYLED_BUTTON"){
					cv.push("V"+e.pin);
				}else if(e.type==="TIMER"){
					tv.push("V"+e.pin);
				}else if(e.type==="NUMBER_INPUT"){
					iv.push("V"+e.pin);
				}
            });
            this.setState({blynkData:dat,gaugeVar:gv,levelVar:lv,ctrlVar:cv,timerVar:tv,inputVar:iv});
        })
	}
	reOrder = (id,pos,dir) => {
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=reOrderTopic&id='+id+'&pos='+pos+'&dir='+dir+'&ord='+this.state.topicOrd.substring(2);
		fetch(url)
		.then(res=>res.json())
		.then(data=>{
			
		});
	}
	setVisible = (id,chid,viz) => {
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=setVisible&tid='+id+'&viz='+viz+'&chid='+chid;
		fetch(url)
		.then(res=>res.json())
		.then(data=>{
			
		});
	}
	setPlugin = (id) => {
		this.setState({plugPercent:0,mlPlugin:[]});
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=plugin&uid='+this.state.userId+'&cid='+this.state.classId+'&type='+id;
		fetch(url)
		.then(res=>res.json())
		.then(data=>{
			this.setState({modalPlugin:false,plugPercent:100});
			this.loadGameList();
			this.createGameRender();
			if(id!=='KWL'){
				this.getPlugins(true);
			}
		});
	}
	delPlugin = (id) => {
		this.setState({plugPercent:0,mlPlugin:[]});
		let url = 'https://comedkku.net/projects/e3diot/e3diot.php?act=unplug&uid='+this.state.userId+'&cid='+this.state.classId+'&type='+id;
		fetch(url)
		.then(res=>res.json())
		.then(data=>{
			this.state.plugPercent = 100;
			this.loadGameList();
			this.createGameRender();
			if(id!=='KWL'){
				this.getPlugins(true);
			}
		});
	}
	render(){
		let dashid = (this.state.blynkData!=undefined)?this.state.blynkData.profile.dashBoards[0].id:"";
		let devid = (this.state.blynkData!=undefined)?this.state.blynkData.profile.dashBoards[0].devices[0].id:"";
		let acc = [];
		let accF = [];
		let tmLab = 1;
		var foldN = -1;
		let games = (this.state.blynkData!=undefined)?this.state.blynkData.profile.dashBoards[0].widgets.sort((a,b)=>a.y-b.y).map((ele,i)=>{
            var out = "";
            if(ele.type==="GAUGE"){
                out = (
                    <Segment>
                        <h3>{ele.label}</h3>
                        <Chart style={{display:'inline-block'}}
                        chartType="Gauge"
                        loader={<div>Loading Gauge</div>} 
                        data={[['Label','Value'],[ele.label.substring(ele.label.lastIndexOf("("),ele.label.lastIndexOf(")")+1),this.state.gaugeDat.filter(ee=>ee.name==="V"+ele.pin).map(ee=>ee.value)[0]]]} 
                        options={{widht:300, height:150, redFrom:90, redTo:100, yellowFrom:75, yellowTo:90, minorTicks:5,}}/>
                    </Segment>
                )
            }else if(ele.type==="LEVEL_DISPLAY"){
				out = (<Segment>
					<h3>{ele.label}</h3>
					<Progress percent={100*Number(this.state.levelDat.filter(ee=>ee.name==="V"+ele.pin).map(ee=>ee.value)[0])} indicating progress></Progress>
				</Segment>)
			}else if(ele.type==="SEGMENTED_CONTROL"){
				let chk = false;
				var btns = ele.labels.map((e,j)=>{
					let v = this.state.ctrlDat.filter(ee=>ee.name==="V"+ele.pin).map(ee=>ee.value)[0];
					chk = (v==-1)?true:chk;
					var act = (v==j+1)?
					<Button active onClick={(e)=>this.setBlynk("V"+ele.pin,j+1)}>{e}</Button>:<Button onClick={(e)=>this.setBlynk("V"+ele.pin,j+1)}>{e}</Button>;
					return act
				});
				out = <Segment><ButtonGroup>{btns}</ButtonGroup></Segment>
				if(chk)out = <Segment><Button loading></Button></Segment>
			}else if(ele.type==="STYLED_BUTTON"){
				let v = this.state.ctrlDat.filter(ee=>ee.name==="V"+ele.pin).map(ee=>ee.value)[0];
				var act = (v==1)?
				<Button color="red" active onClick={(e)=>{this.setBlynk("V"+ele.pin,(v==0)?1:0,false)}}>{ele.label+" [ON]"}</Button>:
				<Button basic color="red" content={ele.label+" [OFF]"} onClick={(e)=>{this.setBlynk("V"+ele.pin,(v==0)?1:0,false)}}/>;
				if(v==-1){
					act = <Button loading>Loading</Button>
				}
				out = <Segment>{act}</Segment>
			}else if(ele.type==="NUMBER_INPUT"){
				let v = this.state.inputDat.filter(ee=>ee.name==="V"+ele.pin).map(ee=>ee.value)[0];
				v = (v==undefined)?-1:Number(v);
				var act = <Input fluid label={ele.label+" [ "+v+" ] "} onChange={(e)=>{
					let p = this.state.inputVal;
					p["V"+ele.pin] = e.target.value;
					this.setState({inputVal:p});
				}} action={{onClick:()=>{this.setBlynk("V"+ele.pin,this.state.inputVal["V"+ele.pin],false)},content:"SET"}}/>;
				if(v==-1)act=<Loader active inline/>
				out = <Segment>{act}</Segment>
			}else if(ele.type==="TIMER"){
				let v = this.state.timerDat.filter(ee=>ee.name==="V"+ele.pin).map(ee=>ee.value)[0];
				let sttm = "-";
				let entm = "-";
				if(ele.startTimeX!=undefined&&ele.startTimeX!="undefined"){
					let SH = Math.floor(Number(ele.startTimeX)/3600);
					let SHT = (SH-17<0)?SH-17+24:SH-17;
					let SM = Math.floor((Number(ele.startTimeX)-(SH*3600))/60);
					let SMT = (SM<10)?"0"+SM:SM;
					let SS = Number(ele.startTimeX)-((SH*3600)+(SM*60));
					let SST = (SS<10)?"0"+SS:SS;
					SHT = (SHT<10)?"0"+SHT:SHT;
					sttm = (ele.startTimeX==-1)?"-":SHT+":"+SMT+":"+SST;
				}
				if(ele.stopTimeX!=undefined&&ele.stopTimeX!="undefined"){
					let EH = Math.floor(Number(ele.stopTimeX)/3600);
					let EHT = (EH-17<0)?EH-17+24:EH-17;
					let EM = Math.floor((Number(ele.stopTimeX)-(EH*3600))/60);
					let EMT = (EM<10)?"0"+EM:EM;
					let ES = Number(ele.stopTimeX)-((EH*3600)+(EM*60));
					let EST = (ES<10)?"0"+ES:ES;
					EHT = (EHT<10)?"0"+EHT:EHT;
					entm = (ele.stopTimeX==-1)?"-":EHT+":"+EMT+":"+EST;
				}
				var act = <Segment.Group horizontal>
					<Segment style={{width:'200px'}}>
						<div>{"Start [ "+sttm+" ] "}</div>
						<select onChange={(e)=>this.setTimerValue("V"+ele.pin+"S",e,"h")}>{hrsOptions}</select>:
						<select onChange={(e)=>this.setTimerValue("V"+ele.pin+"S",e,"m")}>{minOptions}</select>:
						<select onChange={(e)=>this.setTimerValue("V"+ele.pin+"S",e,"s")}>{minOptions}</select>
					</Segment>
					<Segment>
						<Button onClick={()=>{this.setTimer(["startTimeX",dashid,devid,ele.id],"V"+ele.pin+"S",ele.startTimeX)}}>SET</Button>
					</Segment>
					</Segment.Group>;
				var act2 = <Segment.Group horizontal>
					<Segment style={{width:'200px'}}>
						<div>{"End [ "+entm+" ] "}</div>
						<select onChange={(e)=>this.setTimerValue("V"+ele.pin+"E",e,"h")}>{hrsOptions}</select>:
						<select onChange={(e)=>this.setTimerValue("V"+ele.pin+"E",e,"m")}>{minOptions}</select>:
						<select onChange={(e)=>this.setTimerValue("V"+ele.pin+"E",e,"s")}>{minOptions}</select>
					</Segment>
					<Segment><Button onClick={()=>{this.setTimer(["stopTimeX",dashid,devid,ele.id],"V"+ele.pin+"E",ele.stopTimeX)}}>SET</Button></Segment>
				</Segment.Group>;
				out = <Segment.Group><Segment><h4>TIMER {tmLab} [{(v==1)?"ON":"OFF"}]</h4></Segment>{act}{act2}</Segment.Group>
				tmLab += 1;
			}else if(ele.type==="DEVICE_SELECTOR"){
				foldN += 1;
				tmLab = 1;
				acc.push({title:ele.label,content:[]});
				accF.push(true);
			}
			if(foldN>-1)acc[foldN]["content"].push(out);
            return (foldN==-1)?out:[];
        }):[];
		let topMenu = [<Navbar className="Navbar" style={{backgroundColor:this.state.classObj.theme}} variant="dark" expand="lg">
						<Navbar.Brand href="#home" style={{lineHeight:'40px',fontSize:'1.5em'}}>
							{this.state.classObj.title}
						</Navbar.Brand>
					</Navbar>,];
		return (
		<div className="App">
			{topMenu}
			<Container className="mt-3">
            {games}
			{acc.map((e,i)=>{
				return <Segment inverted tertiary color={this.state.segColor[i]} fluid styled>
				<h4>{e.title}</h4>
				{e.content}
			</Segment>})}
			</Container>
		</div>
		);
	}
}

export default Device;
