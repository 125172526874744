import React from 'react';
import { Router, Route } from 'react-router';

import App from './Main/App';
import Device from './Main/Device';
import Chapter from './Main/Chapter';

const Routes = (props) => (
	<Router {...props}>
		<Route path="/" component={App}/>
		<Route path="/:userId" component={App}/>
		<Route path="/device/:userId/:classId" component={Chapter}/>
		<Route path="/device/:userId/:classId/:blynkId/:blynkToken" component={Device}/>
	</Router>
);

export default Routes;